<template>
  <section class="main">
    <div>
      <div class="columns is-multiline">
        <div class="column is-12">
          <h1 class="is-size-3"><b>Assessment Setup</b></h1>
        </div>
        <div class="column bar">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li>
                <h3 class="is-size-6">Assessment</h3>
              </li>
              <li>
                <router-link :to="{ name: `assessment` }">
                  <h3 class="is-size-6">Assessment Setup</h3>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: `showAssessment` }">
                  <h3 class="is-size-6">Assessment Detail</h3>
                </router-link>
              </li>
              <li>
                <h3 class="is-size-6 breadcrumb-item">
                  Detail Section
                </h3>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div class="columns">
        <div class="column is-6">
          <h1 class="title">Section</h1>
        </div>
        <div class="column is-6">
          <b-field grouped position="is-right">
            <router-link
              :to="{ name: 'editAssessmentSection' }"
              title="Edit Section"
            >
              <b-button type="is-hcc" native-type="button">Edit</b-button>
            </router-link>
          </b-field>
        </div>
      </div>

      <div class="box">
        <div class="columns is-multiline is-gapless">
          <!-- For Title -->
          <div class="column is-3" style="height: 45px;">
            <b>Title</b>
          </div>
          <div class="column is-9">
            {{ data.title }}
          </div>

          <!-- For category -->
          <div class="column is-3" style="height: 45px;">
            <b>Category</b>
          </div>
          <div class="column is-9">
            {{ data.category[0].name }}
          </div>

          <!-- For random type -->
          <div class="column is-3" style="height: 45px;">
            <b>Random Type</b>
          </div>
          <div class="column is-9">
            {{ data.isRandomTag == true ? 'Used' : 'Not Used' }}
          </div>

          <!-- For time -->
          <div class="column is-3" style="height: 45px;">
            <b>Time</b>
          </div>
          <div class="column is-9">
            {{ data.isUseTime == true ? 'Used' : 'Not Used' }}
            <span v-if="data.isUseTime == true">
              <b>({{ data.time }} seconds)</b>
            </span>
          </div>

          <!-- For is use introduction -->
          <div class="column is-3">
            <b>Introduction</b>
          </div>
          <div class="column is-9">
            {{ data.isUseIntroduction == true ? 'Used' : 'Not Used' }}
          </div>

          <!-- For introduction -->
          <template v-if="data.isUseIntroduction == true">
            <div class="column is-3"></div>
            <div class="column is-9 mb-3">
              <div v-html="data.introduction"></div>
            </div>
          </template>

          <!-- For example -->
          <div class="column is-3">
            <b>Example</b>
          </div>
          <div class="column is-9">
            {{ data.isUseExample == true ? 'Used' : 'Not Used' }}
          </div>

          <!-- For example question -->
          <template v-if="data.isUseExample == true">
            <div class="column is-3"></div>
            <div class="column is-9 mb-3">
              <span
                v-for="(exampleQuestion, index) in data.exampleQuestion"
                :key="index"
              >
                <template v-if="index != data.exampleQuestion.length - 1">
                  {{ exampleQuestion.name }},
                </template>
                <template v-else>{{ exampleQuestion.name }}</template>
              </span>
            </div>
          </template>

          <!-- For question to display -->
          <div class="column is-3">
            <b>Question To Display</b>
          </div>
          <div class="column is-9">
            {{ data.questionToDisplay }}
          </div>
        </div>

        <div
          v-for="(level, index) in data.levels"
          :key="index"
          class="columns is-multiline is-gapless"
        >
          <!-- For level -->
          <div class="column is-3" style="height: 45px;">
            <b>Level</b>
          </div>
          <div class="column is-9">
            {{ level.levelName }}
          </div>

          <!-- For question to show -->
          <div class="column is-3" style="height: 45px;">
            <b>Question To Show</b>
          </div>
          <div class="column is-9">
            {{ level.questionToShow }}
          </div>

          <!-- For tag type -->
          <template v-if="!data.isRandomTag">
            <div class="column is-3" style="height: 45px;">
              <b>Tag Type</b>
            </div>
            <div class="column is-9">
              <span v-for="(tag, index) in level.tag" :key="index">
                <template v-if="index != level.tag.length - 1">
                  {{ tag.name }},
                </template>
                <template v-else>{{ tag.name }}</template>
              </span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'form-section',
  data() {
    return {
      data: {
        title: null,
        category: [],
        isRandomTag: false,
        isUseTime: false,
        time: null,
        isUseExample: false,
        isUseIntroduction: false,
        introduction: null,
        questionToDisplay: 1,
        levels: [
          {
            id: null,
            level: null,
            questionToShow: 1,
            tag: [],
          },
        ],
      },
    }
  },
  created() {
    this.fetchData()

    this.getLevel()
  },
  methods: {
    ...mapActions({
      fetchSection: 'newSection/fetchSection',
    }),

    async fetchData() {
      const response = await this.fetchSection(this.$route.params.newSectionId)

      response.data.category = [response.data.category]

      this.data = response.data
    },
  },
}
</script>
